import { styled } from '@mui/material/styles';
import MUIButton from '@mui/material/Button';

import { palette } from '@utils/theme';

const miniSizeStyle = {
  texTransform: 'capitalize !important',
  minWidth: '40px',
  height: '25px',
  lineHeight: '1.1rem',
  padding: '5px 10px'
};

export const StyledButton = styled(MUIButton)(
  ({ variant, color, size, disabled, filterVariant }) => ({
    '&.MuiButton-root': {
      textTransform: 'initial',
      minWidth: variant === 'text' ? 'auto' : '100px',
      whiteSpace: 'nowrap',
      boxShadow: 'none',
      paddingTop: '3px',
      paddingBottom: '3px',
      border: variant !== 'text' && `1px solid`,
      borderColor: disabled ? 'inherit' : palette[color],
      padding: variant === 'text' && 0,
      ...(size === 'mini' && { ...miniSizeStyle }),
      ...(filterVariant && {
        height: '32px',
        margin: 'auto',
        border: '0px !important',
        textTransform: 'uppercase'
      })
    }
  })
);
