export const handleAsyncThunk = (builder, asyncThunk, key) => {
  builder.addCase(asyncThunk.pending, (state) => {
    state[key].isLoading = true;
  });
  builder.addCase(asyncThunk.fulfilled, (state, { payload }) => {
    state[key].data = payload;
    state[key].isLoading = false;
  });
  builder.addCase(asyncThunk.rejected, (state, { error }) => {
    state[key].error = error;
    state[key].isLoading = false;
  });
};
