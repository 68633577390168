import React from 'react';
import PT from 'prop-types';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';

import Button from '@components/shared/Button/Button';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Box from '@components/shared/Box/Box';
import Grid from '@components/shared/Grid/Grid';

const getFlexForField = (gap) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: `${gap}px`,
  flexWrap: 'wrap'
});

const DateRangeInTwoInputs = (props) => {
  const { item, applyValue } = props;

  const initialValues = {
    startDate:
      item.value?.[0] ||
      dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD h:mm A'),
    endDate: item.value?.[1] || dayjs().endOf('day').format('YYYY-MM-DD h:mm A')
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const payload = [dayjs(values.startDate), dayjs(values.endDate)];

    if (values.startDate !== null && values.endDate !== null) {
      applyValue({ ...item, value: payload });
    }

    setSubmitting(payload);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <Form>
        <Box sx={getFlexForField(20)}>
          <Grid item sm={4}>
            <FormikField
              type="date"
              name="startDate"
              label="Period from"
              size="small"
            />
          </Grid>
          <Grid item sm={4}>
            <FormikField
              type="date"
              name="endDate"
              label="Period to"
              size="small"
            />
          </Grid>
        </Box>
        <Button type="submit" filterVariant>
          Apply
        </Button>
      </Form>
    </Formik>
  );
};

DateRangeInTwoInputs.propTypes = {
  item: PT.array,
  applyValue: PT.func
};

export default DateRangeInTwoInputs;
