import { createTheme } from '@mui/material/styles';

export const palette = {
  primary: '#1890ff',
  secondary: '#e6f7ff',
  dark: '#2b2b2b',
  darkBackground: '#a1a1a1',
  white: '#fff',
  black: '#000',
  lightGray: '#f4f4f4',
  grey1: '#f8f9fb',
  grey2: 'rgba(209, 217, 226, 0.4)',
  buttonGrey: '#e6e9ec',
  border: '#d1d9e2',
  disabledBackground: 'rgba(0, 0, 0, 0.12)',
  rowBackground: '#fff',
  tableHeaderBackground: '#fafafa',
  tableLoaderBackground: 'rgba(255, 255, 255, 0.8)',
  tableHoverColor: '#d1d1d1',
  redCellColor: 'rgba(205, 92, 92, 0.5)',
  error: '#bc2627',
  success: '#3e9314',
  warning: '#faad14'
};

export const theme = createTheme({
  palette: {
    ...palette,
    primary: {
      main: palette.primary
    },
    primaryText: {
      main: palette.primary
    },
    titleText: {
      main: palette.dark
    },
    border: {
      main: palette.border
    },
    secondary: {
      main: palette.secondary
    },
    error: {
      main: palette.error
    },
    success: {
      main: palette.success
    },
    warning: {
      main: palette.warning
    }
  },
  typography: {
    h1: {
      fontSize: '28px',
      lineHeight: '1.2em',
      fontWeight: 600,
      color: palette.dark
    },
    h2: {
      fontSize: '24px',
      lineHeight: '1.2em',
      fontWeight: 600,
      textTransform: 'capitalize',
      color: palette.dark
    },
    h3: {
      fontSize: '20px',
      lineHeight: '1.2em',
      fontWeight: 600,
      color: palette.dark
    },
    h4: {
      fontSize: '18px',
      lineHeight: '1.2em',
      fontWeight: 600,
      color: palette.dark
    },
    h5: {
      fontSize: '16px',
      lineHeight: '1.2em',
      fontWeight: 600,
      color: palette.dark
    },
    h6: {
      fontSize: '14px',
      lineHeight: '1.2em',
      fontWeight: 600,
      color: palette.dark
    },
    subtitle1: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400
    },
    body2: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 400
    },
    strong: {
      fontWeight: 600
    }
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '14px',
          padding: '6px 10px !important',
          marginTop: '1px !important'
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          overflow: 'visible'
        },
        panelContent: {
          overflow: 'visible'
        },
        filterFormLogicOperatorInput: {
          marginTop: 0,
          marginBottom: 16
        },
        filterFormValueInput: {
          marginTop: 0,
          marginBottom: 0,
          minWidth: 200,
          width: 'initial',
          maxWidth: 300,
          height: '100% !important',
          justifyContent: 'flex-end'
        },
        filterFormDeleteIcon: {
          marginTop: 12,
          justifyContent: 'center'
        }
      }
    },
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          padding: '0 !important'
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '40px !important'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          marginTop: '45px'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: '8px',
          marginBottom: '8px'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '2px 10px'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '1em'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          paddingTop: '1px !important',
          paddingBottom: '1px !important'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          marginLeft: '2px'
        }
      }
    }
  }
});
