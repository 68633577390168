const buildInMuiDataTableTypes = {
  string: 'string',
  number: 'number',
  date: 'date',
  dateTime: 'dateTime',
  boolean: 'boolean',
  singleSelect: 'singleSelect'
};

const customMuiDataTableTypes = {
  dateRange: 'dateRange',
  fullName: 'fullName',
  expDate: 'expDate'
};

const muiDataTableTypes = {
  ...buildInMuiDataTableTypes,
  ...customMuiDataTableTypes
};

const dateMuiODataOperators = [
  ['is', 'eq'],
  ['not', 'ne'],
  ['after', 'gt'],
  ['onOrAfter', 'ge'],
  ['before', 'lt'],
  ['onOrBefore', 'le'],
  ['isEmpty', 'eq'],
  ['isNotEmpty', 'ne']
];

const filterMuiODataOperators = {
  [buildInMuiDataTableTypes.string]: [
    ['equals', 'eq'],
    ['contains', 'contains'],
    ['startsWith', 'startswith'],
    ['endsWith', 'endswith'],
    ['isEmpty', 'eq'],
    ['isNotEmpty', 'ne'],
    ['isAnyOf', 'in']
  ],
  [buildInMuiDataTableTypes.number]: [
    ['=', 'eq'],
    ['!=', 'ne'],
    ['>', 'gt'],
    ['>=', 'ge'],
    ['<', 'lt'],
    ['<=', 'le'],
    ['isEmpty', 'eq'],
    ['isNotEmpty', 'ne'],
    ['isAnyOf', 'in']
  ],
  [buildInMuiDataTableTypes.date]: dateMuiODataOperators,
  [buildInMuiDataTableTypes.dateTime]: dateMuiODataOperators,
  [buildInMuiDataTableTypes.boolean]: [['is', 'eq']],
  [buildInMuiDataTableTypes.singleSelect]: [
    ['is', 'eq'],
    ['not', 'ne'],
    ['isAnyOf', 'in']
  ],
  [customMuiDataTableTypes.dateRange]: ['ge', 'le'],
  [customMuiDataTableTypes.fullName]: ['contains'],
  [customMuiDataTableTypes.expDate]: ['eq']
};

const muiFilterFunctions = {
  dateRange: (filterItem) => (rows) => {
    if (filterItem.operatorValue.start && filterItem.operatorValue.end) {
      const startDate = filterItem.operatorValue.start.toISOString();
      const endDate = filterItem.operatorValue.end.toISOString();
      return rows.filter((row) => row.date >= startDate && row.date <= endDate);
    }
    return rows;
  }
};

export { muiDataTableTypes, filterMuiODataOperators, muiFilterFunctions };
