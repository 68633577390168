import React from 'react';
import PT from 'prop-types';
import Link from 'next/link';
import MuiIconButton from '@mui/material/IconButton';

import { getButtonStyle } from './IconButton.utils';

import { PUBLIC_URL } from '@constants/common';

const IconButton = ({
  color = 'primary',
  sx,
  icon,
  children,
  to,
  ...props
}) => {
  if (to) {
    return (
      <Link href={PUBLIC_URL + to} passHref>
        <MuiIconButton sx={getButtonStyle(color, sx)} {...props}>
          {icon || children}
        </MuiIconButton>
      </Link>
    );
  } else {
    return (
      <MuiIconButton sx={getButtonStyle(color, sx)} {...props}>
        {icon || children}
      </MuiIconButton>
    );
  }
};

IconButton.propTypes = {
  to: PT.string,
  tooltipProps: PT.object,
  onClick: PT.func,
  color: PT.string,
  children: PT.element,
  icon: PT.element,
  sx: PT.object
};

export default IconButton;
