import React, { useEffect, useRef } from 'react';
import PT from 'prop-types';
import { Field } from 'formik';

import { useIsSchemaRequiredField } from '../SchemaProvider/useIsSchemaRequiredField.hook';

import FormikDateRangeInput from '@components/shared/Form/DateRangeInput/DateRangeInput';
import { FormikRadio } from '@components/shared/Form/Radio';
import { FormikFormInput } from '@components/shared/Form/Input';
import { FormikDateTimeInput } from '@components/shared/Form/DateTimeInput/DateTimeInput';
import { FormikCheckbox } from '@components/shared/Form/Checkbox';
import { FormikFormSelect } from '@components/shared/Form/Select/Select';
import InputMasked from '@components/shared/Form/InputMasked/InputMasked';
import { FormikTimeInput } from '@components/shared/Form/TimeInput/TimeInput';
import FormHelperText from '@components/shared/Form/FormHelperText';
import Box from '@components/shared/Box/Box';

export const fieldTypes = {
  checkbox: 'checkbox',
  radio: 'radio',
  select: 'select',
  autocomplete: 'autocomplete',
  textarea: 'textarea',
  text: 'text',
  number: 'number',
  email: 'email',
  password: 'password',
  date: 'date',
  dateRange: 'dateRange',
  mask: 'mask',
  time: 'time'
};

export const fieldTypeComponents = {
  [fieldTypes.mask]: InputMasked,
  [fieldTypes.date]: FormikDateTimeInput,
  [fieldTypes.dateRange]: FormikDateRangeInput,
  [fieldTypes.checkbox]: FormikCheckbox,
  [fieldTypes.select]: FormikFormSelect,
  [fieldTypes.autocomplete]: FormikFormSelect,
  [fieldTypes.radio]: FormikRadio,
  [fieldTypes.time]: FormikTimeInput
};

const FormikField = ({ type, sx, required, ...props }) => {
  const inputRef = useRef(null);
  const isRequired = useIsSchemaRequiredField(props.name) || required;

  useEffect(() => {
    inputRef.current?.form[0]?.focus();
  }, [inputRef.current]);

  const Component = fieldTypeComponents[type] || FormikFormInput;

  if (type === fieldTypes.mask || type === fieldTypes.editor) {
    return (
      <Field {...props}>
        {({ field, meta, form }) => {
          const isInvalid = !!(meta.touched && meta.error);
          return (
            <Box sx={sx}>
              <Component
                inputRef={inputRef}
                id={field.name}
                type={type}
                {...props}
                {...field}
                required={isRequired}
                formikField
                setError={(e) => {
                  setTimeout(() => {
                    // timer, because we need formik onBlur validation finish first
                    form.setFieldError(field.name, e);
                  }, 0);
                }}
                value={field.value}
                invalid={isInvalid}
                onChange={
                  props.onChange
                    ? (e) => props.onChange(e, field, form)
                    : field.onChange
                }
                className="form-field__component"
              />
              {isInvalid && (
                <FormHelperText
                  error={!!isInvalid}
                  sx={{ mt: '-5px', mb: '5px' }}
                >
                  {meta.error}
                </FormHelperText>
              )}
            </Box>
          );
        }}
      </Field>
    );
  }

  return (
    <Box sx={sx}>
      <Component
        inputRef={inputRef}
        id={props.name}
        type={fieldTypes[type] || fieldTypes.text}
        {...props}
        variant={props.variant || 'outlined'}
        required={isRequired}
      />
    </Box>
  );
};

FormikField.defaultProps = {
  type: fieldTypes.text,
  fullWidth: true
};

FormikField.propTypes = {
  label: PT.oneOfType([PT.string, PT.element]),
  name: PT.string.isRequired,
  type: PT.oneOf(Object.values(fieldTypes)),
  onChange: PT.func,
  multiple: PT.bool,
  variant: PT.oneOf(['standard', 'outlined', 'filled']),
  sx: PT.object,
  required: PT.bool
};

export default FormikField;
