import { configureStore } from '@reduxjs/toolkit';

import ui from './ui';
import currentUser from './currentUser';

export const store = configureStore({
  reducer: {
    ui,
    currentUser
  },
  devTools: process.env.NODE_ENV !== 'production'
});
