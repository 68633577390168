import axios from 'axios';
import qs from 'query-string';

import { refreshTokenHandler } from '@utils/sessionManagementHelpers';
import AuthSession from '@utils/auth-session';
import { apiBaseUrl } from '@constants/api';
import helpers from '@utils/helpers';

const headers = {
  'Accept-Language': 'en',
  'Content-Type': 'application/json'
};

const Api = axios.create({
  baseURL: apiBaseUrl,
  headers,
  paramsSerializer: function (params) {
    // is we detect at least one params which start with '$' - we are using odata-query
    if (Object.keys(params).some((paramKey) => paramKey.startsWith('$'))) {
      return helpers.parseCSVODataRequest(params);
    }

    return qs.stringify(params);
  }
});

Api.interceptors.request.use(
  async (config) => {
    if (
      AuthSession.shouldRefresh(+AuthSession.refreshTime) &&
      !config.refreshToken
    ) {
      AuthSession.startRefreshToken();
      const data = await refreshTokenHandler();
      AuthSession.endRefreshToken();

      AuthSession.set({
        token: data.access_token,
        permissions: data.access_policy,
        refreshToken: data.refresh_token,
        expiresIn: data.expires_in,
        issued: data['.issued'],
        role: data.role
      });
    }

    const permissions = AuthSession.getPermissions();
    if (permissions) {
      const parsedPermissions = JSON.parse(permissions);
      const hasOneDayPermissionOnly = parsedPermissions.length === 1; // TODO: check if it needs
      const wasTokenGrantedYesterday = AuthSession.wasTokenGrantedYesterday();
      if (hasOneDayPermissionOnly && wasTokenGrantedYesterday) {
        AuthSession.logout();
        throw new axios.Cancel('Token has expired');
      }
    }

    return {
      ...config,
      url: encodeURI(config.url)
    };
  },
  (error) => Promise.reject(error)
);

Api.interceptors.response.use(
  (res) => res.data,
  (err) => {
    if (err.response) {
      switch (err.response.status) {
        case 401:
          AuthSession.logout();
          break;

        default:
          break;
      }
    }
    return Promise.reject(helpers.handleServerErrors(err));
  }
);

export default Api;
