import React from 'react';
import PT from 'prop-types';

import ConfirmActionButton from './ConfirmActionButton';

import { DeleteIcon } from '@components/shared/icons/Icons';

const DeleteActionButton = ({
  hidden,
  itemId,
  content,
  onConfirm,
  ...props
}) => (
  <ConfirmActionButton
    hidden={hidden}
    icon={<DeleteIcon />}
    modalProps={{
      itemId,
      content,
      onConfirm
    }}
    {...props}
  />
);

DeleteActionButton.defaultProps = {
  hidden: false,
  content: 'Are you sure to delete?'
};

DeleteActionButton.propTypes = {
  hidden: PT.bool,
  content: PT.string.isRequired,
  itemId: PT.oneOfType([PT.string, PT.number]).isRequired,
  onConfirm: PT.func.isRequired,
  disabled: PT.bool
};

export default DeleteActionButton;
