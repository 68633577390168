import { getGridStringOperators } from '@mui/x-data-grid-premium';

import DateRangeInTwoInputs from '@components/shared/DataTable/filters/DateRangeInTwoInputs';
import FullNameFilter from '@components/shared/DataTable/filters/FullNameFilter';
import filterHelpers from '@components/shared/ODataPaginatedTable/ODataPaginatedTable.utils';

export const unsetOption = {
  label: 'Unset',
  value: ''
};

export const fieldWidth = { width: '215px !important' };
export const dateTimeFieldWidth = { width: '230px !important' };

export const booleanFilterFieldOptions = [
  unsetOption,
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

const getApplyFilterOption = (filterItem) => {
  const { value } = filterItem;
  return (params) => params?.value === value || !value;
};

export const filterOperators = {
  fullName: {
    label: 'contains',
    value: 'fullName',
    InputComponent: FullNameFilter
  },
  dateRange: {
    label: 'Date Range',
    value: 'dateRange',
    getApplyFilterFn: filterHelpers.getApplyFilterFn,
    InputComponent: DateRangeInTwoInputs
  },
  equals: (InputComponent) => ({
    label: 'equals',
    value: 'eq',
    getApplyFilterFn: getApplyFilterOption,
    InputComponent
  }),
  getMuiOperator: (userOperator) =>
    getGridStringOperators().filter(
      (operator) => operator.value === userOperator
    )
};
