import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { useContext, useMemo } from 'react';
import * as Yup from 'yup';

import { SchemaContext } from '@components/shared/Form/SchemaProvider';
import Logger from '@utils/logger';

export const useIsSchemaRequiredField = (name) => {
  const { schema } = useContext(SchemaContext);
  const { values } = useFormikContext();

  return useMemo(() => {
    if (!schema) return false;

    let fieldSchema = null;

    const fieldName = name.replace(/\[\d+\]/, '');

    try {
      fieldSchema = Yup.reach(schema, fieldName, values);
    } catch (e) {
      Logger.error(
        `[useIsSchemaRequiredField]: field with key ${fieldName} isn't described in form schema`
      );
      return false;
    }

    const isRequired = !!fieldSchema
      .describe()
      .tests.find((test) => test.name === 'required');

    if (isRequired || !fieldSchema._conditions?.length) return isRequired;

    const conditionalSchema = fieldSchema.resolve({
      path: name,
      value: get(values, name),
      parent: values
    });

    return conditionalSchema
      .describe()
      .tests.find((test) => test.name === 'required');
  }, [schema, values]);
};
