import { parseCookies, setCookie, destroyCookie } from 'nookies';

class Cookies {
  static get(name, ctx) {
    const cookies = parseCookies(ctx);
    return cookies[name];
  }

  static set(name, val, opts = { path: '/' }, ctx) {
    setCookie(ctx, name, val, opts);
  }

  static remove(name, ctx, opts = { path: '/' }) {
    destroyCookie(ctx, name, opts);
  }
}

export default Cookies;
