import React from 'react';

import routes from './routes';
import { permissionsMap } from './permissions';

import {
  ApplicationUsersIcon,
  ChangeAuditIcon,
  ConfigurationIcon,
  PaymentsIcon
} from '@components/shared/icons/Icons';

export const userTokenCookiesKey = 'app-token';

export const PUBLIC_URL = process.env.NEXT_PUBLIC_URL;
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const MUI_LICENSE_KEY = process.env.NEXT_PUBLIC_MUI_LICENSE_KEY;
export const SESSION_KEY = '__user-token__';
export const REFRESH_KEY = '__user-refresh-token__';
export const EXPIRE_KEY = '__user-expires__';
export const REFRESH_PROCESSING_KEY = '__user-refresh-processing__';
export const PERMISSION_KEY = '__permissions__';
export const ISSUED_KEY = '__token-issued__';
export const DEVICE_UUID_KEY = '__device-uuid__';
export const REFRESH_TIME = process.env.REFRESH_TIME;
export const REFRESH_TIME_SINGLE_PAGES = process.env.REFRESH_TIME_SINGLE_PAGES;
export const ROLE_KEY = '__user-role__';
export const ADMIN_ROLE = '["Admin"]';
export const apiXAgent = 'web';
export const REDIRECT_KEY = 'redirectTo';
export const SENTRY_DSN_KEY = process.env.NEXT_PUBLIC_SENTRY_DSN_KEY;

export const grantTypes = {
  refreshToken: 'refresh_token',
  password: 'password'
};

export const mainMenu = [
  {
    title: 'Access',
    link: routes.access.users.index,
    icon: <ConfigurationIcon />,
    children: [
      {
        title: 'Users',
        link: routes.access.users.index
      },
      {
        title: 'Roles',
        link: routes.access.roles.index
      }
      // {
      //   title: 'Allowed IP Addresses',
      //   link: routes.access.allowedIpAddresses.index
      // }
    ]
  },
  {
    title: 'Customers',
    link: routes.customers.index,
    icon: <ApplicationUsersIcon />
  },
  {
    title: 'Debit Cards',
    link: routes.debitCards.index,
    icon: <PaymentsIcon />
  },
  {
    title: 'System Events',
    link: routes.systemEvents.index,
    icon: <PaymentsIcon />
  },
  {
    title: 'Change Audit',
    link: routes.changeAudit.index,
    icon: <ChangeAuditIcon />
  }
];

export const routesPermissionsMap = {
  [routes.customers.index]: {
    perm: permissionsMap.customerView
  },
  [routes.access.index]: {
    perm: [permissionsMap.groupView, permissionsMap.managersView],
    operator: 'OR'
  },
  // [routes.access.allowedIpAddresses.index]: {
  //   perm: permissionsMap.authorizeView
  // },
  [routes.access.users.index]: {
    perm: permissionsMap.managersView
  },
  [routes.access.roles.index]: {
    perm: permissionsMap.groupView
  },
  [routes.debitCards.index]: {
    perm: permissionsMap.debitCardView
  },
  [routes.systemEvents.index]: {
    perm: permissionsMap.logView
  },
  [routes.changeAudit.index]: {
    perm: permissionsMap.auditView
  }
};

export const defaultPageSizeOptions = [10, 25, 50, 100];
export const defaultPageSize = defaultPageSizeOptions[1];

export const dateFormat = 'MM/DD/YYYY';
export const serverDateFormat = 'YYYY-MM-DD';
export const serverDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
export const dateTimeFormat = 'MM/DD/YYYY hh:mm:ss A';
export const dateShortTimeFormat = 'MM/DD/YYYY hh:mm A';
export const filterTimeFormat = 'YYYY-MM-DD HH:mm';
export const dateHoursFormat = 'MM/DD/YYYY hh A';
export const timeFormat = 'hh:mm:ss A';
export const timeOnlyHoursFormat = 'hh A';
export const shortTimeFormat = {
  front: 'hh:mm A',
  server: 'HH:mm'
};
export const cardDateFormat = 'MM/YY';

export const noFieldData = '-';
export const headerHeight = 44;

export const fieldsMasks = {
  phoneUSA: {
    mask: '999 999 9999',
    placeholder: 'XXX XXX XXXX'
  },
  ssn: {
    mask: '999-99-9999',
    placeholder: 'XXX-XX-XXXX'
  },
  cardNumber: {
    mask: '9999-9999-9999-9999',
    placeholder: 'XXXX-XXXX-XXXX-XXXX'
  },
  cardExpire: {
    mask: '99/99',
    placeholder: 'MM/YY'
  },
  accessCode: {
    mask: '999999',
    placeholder: 'XXXXXX'
  }
};

export const actionTypes = {
  add: 'add',
  edit: 'edit',
  details: 'details'
};

export const selectedIdKey = 'selectedId';

export const httpMethodsOptions = [
  { label: 'POST', value: 'POST' },
  { label: 'GET', value: 'GET' },
  { label: 'DELETE', value: 'DELETE' },
  { label: 'PUT', value: 'PUT' },
  { label: 'OPTIONS', value: 'OPTIONS' },
  { label: 'PATCH', value: 'PATCH' }
];
