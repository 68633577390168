import React from 'react';
import PT from 'prop-types';
import { Field } from 'formik';
import MUICheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import FormControl from '@components/shared/Form/FormControl/FormControl';
import FormHelperText from '@components/shared/Form/FormHelperText';
import { palette } from '@utils/theme';

const Checkbox = ({
  error,
  fullWidth,
  margin,
  labelStyles,
  wrapperProps,
  ...rest
}) => (
  <FormControl
    fullWidth={fullWidth}
    margin={margin}
    error={!!error}
    sx={{ flexDirection: 'row', ...wrapperProps.sx }}
    {...wrapperProps}
    size="small"
  >
    <FormControlLabel
      {...rest}
      control={
        <MUICheckbox
          name={rest.name}
          checked={rest.checked || rest.value}
          onChange={rest.onChange}
          size="small"
          sx={{
            margin: '0 !important',
            color: `${rest.color || palette.primary} !important`
          }}
          indeterminate={rest.indeterminate}
        />
      }
      sx={{ width: 'max-content', ...labelStyles }}
      label={rest.label}
    />
    {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
  </FormControl>
);

const FormikCheckbox = ({ name, ...rest }) => (
  <Field name={name}>
    {({ field, meta }) => {
      const error = meta.touched ? meta.error : '';
      return <Checkbox {...field} {...rest} error={error} />;
    }}
  </Field>
);

Checkbox.defaultProps = {
  margin: 'normal',
  fullWidth: false,
  labelStyles: {},
  wrapperProps: {}
};

const propTypes = {
  name: PT.string.isRequired,
  label: PT.any,
  error: PT.string,
  fullWidth: PT.bool,
  helperText: PT.string,
  value: PT.any,
  onChange: PT.func,
  margin: PT.oneOf(['dense', 'none', 'normal']),
  wrapperProps: PT.object,
  labelStyles: PT.object
};

Checkbox.propTypes = propTypes;
FormikCheckbox.propTypes = propTypes;

export { Checkbox, FormikCheckbox };
