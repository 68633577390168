import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import qs from 'query-string';

import Button from '@components/shared/Button/Button';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Box from '@components/shared/Box/Box';

// TODO: move styles to a separate file (srt 47, 65)

const getGridForField = () => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 65px',
  alignItems: 'end'
});

const FullNameFilter = (props) => {
  const { item, applyValue } = props;

  const prefix = item.field.substring(0, item.field.indexOf('/') + 1);

  const parsedLocation = qs.parse(location.search);
  const parsedValues =
    parsedLocation?.$filter && JSON.parse(parsedLocation?.$filter);

  const firstNameKey = `${prefix}firstName`;
  const lastNameKey = `${prefix}lastName`;

  const initialValues = {
    [firstNameKey]: parsedValues?.[firstNameKey]?.contains,
    [lastNameKey]: parsedValues?.[lastNameKey]?.contains
  };

  const handleSubmit = (values, { setSubmitting }) => {
    applyValue({ ...item, value: values });

    setSubmitting(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <Form>
        <Box sx={getGridForField}>
          <FormikField
            type="text"
            name={firstNameKey}
            label="First Name"
            size="small"
            filterVariant
          />
          <FormikField
            type="text"
            name={lastNameKey}
            label="Last Name"
            size="small"
            filterVariant
          />
          <Box sx={{ mb: '6px' }}>
            <Button type="submit" filterVariant>
              Apply
            </Button>
          </Box>
        </Box>
      </Form>
    </Formik>
  );
};

FullNameFilter.propTypes = {
  item: PropTypes.any,
  applyValue: PropTypes.func
};

export default FullNameFilter;
