import { styled } from '@mui/material/styles';
import MuiFormControl from '@mui/material/FormControl';

export const StyledFormControl = styled(MuiFormControl)(
  ({ filterVariant }) => ({
    '& .MuiFormLabel-root': filterVariant && {
      fontSize: '10px',
      top: '-6px',
      transform: 'none'
    }
  })
);
