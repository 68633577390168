import { styled } from '@mui/material/styles';
import MuiSelect from '@mui/material/Select';
import MuiFormControl from '@mui/material/FormControl';
import MuiAutocomplete from '@mui/material/Autocomplete';

import Button from '@components/shared/Button/Button';

export const StyledSelect = styled(MuiSelect)`
  && .MuiSelect-select {
    min-height: unset;
    text-align: left;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
`;

export const StyledAutocomplete = styled(MuiAutocomplete)`
  && {
    .MuiAutocomplete {
      &-input {
        text-align: left;
        padding-left: 4px !important;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  min-width: auto;
`;

export const StyledFormControl = styled(MuiFormControl)(({ hasValue }) => ({
  '& .MuiInputLabel-root': {
    lineHeight: '1.2rem',
    transform: hasValue
      ? 'translate(14px, -7px) scale(0.75) !important'
      : 'translate(14px, 8px) scale(1)'
  }
}));
