import { createAsyncThunk } from '@reduxjs/toolkit';

import Api from '@utils/api';
import { apiUrls } from '@constants/api';
import Logger from '@utils/logger';

export const fetchCurrentUser = createAsyncThunk(
  'currentUser/fetchCurrentUser',
  async () => {
    try {
      return await Api.get(apiUrls.users.current);
    } catch (e) {
      Logger.error(e);
    }
  }
);
