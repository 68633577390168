import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebar: {
    isOpen: true
  }
};

export const selectSidebarIsOpen = ({ ui }) => ui.sidebar.isOpen;

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openSidebar: (state) => {
      state.sidebar.isOpen = true;
    },
    closeSidebar: (state) => {
      state.sidebar.isOpen = false;
    }
  }
});

export const { openSidebar, closeSidebar } = uiSlice.actions;
export default uiSlice.reducer;
