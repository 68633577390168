import { styled } from '@mui/material/styles';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

export const StyledDateRangePicker = styled(DateRangePicker)(
  ({ hasValue }) => ({
    '& .MuiInputLabel-root': {
      lineHeight: '1.2rem',
      transform: hasValue
        ? 'translate(14px, -7px) scale(0.75)'
        : 'translate(10px, 7px) scale(1)'
    },
    '& .MuiInputLabel-root.Mui-focused': {
      transform: 'translate(14px, -7px) scale(0.75) !important'
    }
  })
);
