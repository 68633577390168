export const permissionsMap = {
  debitCardView: 'DebitCardView',
  debitCardManage: 'DebitCardManage',
  customerView: 'CustomerView',
  customerManage: 'CustomerManage',
  managersView: 'ManagersView',
  managersManage: 'ManagersManage',
  groupView: 'GroupView',
  groupManage: 'GroupManage',
  auditView: 'AuditView',
  logView: 'LogView'
};
