import React from 'react';
import PT from 'prop-types';
import dayjs from 'dayjs';
import { Field } from 'formik';
import { TimePicker } from '@mui/x-date-pickers-pro';

import { timeFormat } from '@constants/common';
import FormControl from '@components/shared/Form/FormControl/FormControl';
import FormHelperText from '@components/shared/Form/FormHelperText';

const TimeInput = ({ error, ...props }) => (
  <FormControl fullWidth={props.fullWidth} margin="normal" error={!!error}>
    <TimePicker
      {...props}
      size="small"
      views={['hours', 'minutes']}
      timeSteps={{ hours: 1, minutes: 1 }}
    />
    {!!error && <FormHelperText error={!!error}>{error}</FormHelperText>}
  </FormControl>
);
const FormikTimeInput = ({ name, format = timeFormat, ...rest }) => (
  <Field name={name}>
    {({ meta, field, form }) => {
      const error = meta.touched ? meta.error : '';
      const onChange = (time) => {
        form.setFieldValue(name, dayjs(time));
      };
      const onBlur = (e) => {
        setTimeout(() => {
          if (e?.target?.value) {
            form.setFieldValue(name, dayjs(e.target.value));
          }
          form.setFieldTouched(name, true);
        }, 0);
      };

      return (
        <TimeInput
          {...field}
          {...rest}
          format={format}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
        />
      );
    }}
  </Field>
);

const propTypes = {
  name: PT.string.isRequired,
  label: PT.string.isRequired,
  value: PT.oneOfType([PT.string, PT.object]),
  onChange: PT.func,
  onBlur: PT.func,
  error: PT.oneOfType([PT.bool, PT.string]),
  helperText: PT.string,
  format: PT.string,
  fullWidth: PT.bool
};
TimeInput.propTypes = propTypes;
FormikTimeInput.propTypes = propTypes;

export { FormikTimeInput };
