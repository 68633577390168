import { fieldTypeCleaners, buildCleaner } from 'lodash-clean';
import noop from 'lodash/noop';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';

export const cleanObject = (values = {}) => {
  const cleanTypedObjects = (obj) =>
    mapValues(obj, (value) => {
      if (!isPlainObject(value)) return value;

      const keys = Object.keys(value);

      if (
        keys.length === 2 &&
        keys.includes('type') &&
        keys.includes('value') &&
        (isNil(value.value) || value.value === '')
      ) {
        return null;
      }

      return cleanTypedObjects(value);
    });

  const cleanedValues = cleanTypedObjects(values);

  const cleaner = buildCleaner({
    ...fieldTypeCleaners,
    isNull: noop
  });

  return cleaner(cleanedValues);
};
