import { createSlice } from '@reduxjs/toolkit';

import { fetchCurrentUser } from './actions';

import { handleAsyncThunk } from '@store/helpers';

const initialState = {
  profile: {
    data: null,
    isLoading: false,
    error: null
  }
};

export const selectCurrentUser = ({ currentUser }) => currentUser.profile.data;
export const selectErrorCurrentUser = ({ currentUser }) =>
  currentUser.profile.error;

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    clearCurrentUser: (state) => {
      state.profile.data = null;
    }
  },
  extraReducers: (builder) => {
    handleAsyncThunk(builder, fetchCurrentUser, 'profile');
  }
});

export const { clearCurrentUser } = currentUserSlice.actions;

export default currentUserSlice.reducer;
