import React, { useEffect } from 'react';
import PT from 'prop-types';
import { Provider } from 'react-redux';
import { LicenseInfo } from '@mui/x-license-pro';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { ToastContainer } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import 'react-toastify/dist/ReactToastify.css';
import 'normalize.css/normalize.css';

import AuthSession from '@utils/auth-session';
import yupLocale from '@constants/yupLocale';
import { DEVICE_UUID_KEY, MUI_LICENSE_KEY } from '@constants/common';
import useIsomorphicLayoutEffect from '@hooks/useIsomorphicEffect';
import { theme } from '@utils/theme';
import { store } from '@store/index';
import api from '@utils/api';
import Storage from '@utils/storage';
import Helpers from '@utils/helpers';

/*
    Redefine useLayoutEffect with useIsomorphicLayoutEffect
    for prevent useLayoutEffect calls on the server.
*/
React.useLayoutEffect = useIsomorphicLayoutEffect;

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
Yup.setLocale(yupLocale);
AuthSession.setHeader();
[customParseFormat, isSameOrAfter, isSameOrBefore, localeData, weekday].forEach(
  dayjs.extend
);

const MyApp = ({ Component, pageProps }) => {
  useEffect(() => {
    const deviceUUID = Storage.get(DEVICE_UUID_KEY) || uuidv4();
    if (Helpers.isClient() && !Storage.get(DEVICE_UUID_KEY)) {
      Storage.set(DEVICE_UUID_KEY, deviceUUID);
    }
    api.defaults.headers['X-Device'] = deviceUUID;
  }, []);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <>
            <CssBaseline />
            <Component {...pageProps} />
            <ToastContainer hideProgressBar={true} />
          </>
        </Provider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

MyApp.propTypes = {
  Component: PT.any,
  pageProps: PT.any
};

export default MyApp;
