import React from 'react';
import PT from 'prop-types';
import { Field } from 'formik';
import RadioGroup from '@mui/material/RadioGroup';
import MUIRadio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import Typography from '@components/shared/Typography/Typography';
import FormHelperText from '@components/shared/Form/FormHelperText';
import FormControl from '@components/shared/Form/FormControl/FormControl';

const Radio = ({
  options,
  margin,
  fullWidth,
  error,
  controlProps,
  disabled,
  flexDirection,
  ...rest
}) => (
  <FormControl margin={margin} fullWidth={fullWidth} error={!!error}>
    {rest.label && (
      <FormLabel>
        <Typography variant="h6">{rest.label}</Typography>
      </FormLabel>
    )}
    <RadioGroup {...rest} sx={{ flexDirection }}>
      {options?.map(({ value, label, description }) => {
        const customLabel = description ? <strong>{label}</strong> : label;
        return (
          <div key={value}>
            <FormControlLabel
              control={<MUIRadio {...controlProps} color="primary" />}
              disabled={disabled}
              label={customLabel}
              value={value}
            />
            {description && <p>{description}</p>}
          </div>
        );
      }) || (
        <FormControlLabel control={<MUIRadio color="primary" />} {...rest} />
      )}
      <FormHelperText error={!!error}>{error}</FormHelperText>
    </RadioGroup>
  </FormControl>
);

const FormikRadio = ({ name, ...rest }) => (
  <Field name={name}>
    {({ field, meta }) => {
      const error = meta.touched ? meta.error : '';
      return <Radio {...field} {...rest} error={error} />;
    }}
  </Field>
);

Radio.defaultProps = {
  margin: 'normal',
  flexDirection: 'column'
};

const RadioOption = PT.shape({
  label: PT.string.isRequired,
  value: PT.string.isRequired
});

const propTypes = {
  name: PT.string.isRequired,
  label: PT.string,
  options: PT.arrayOf(RadioOption),
  radioGroupClassName: PT.string,
  fullWidth: PT.bool,
  error: PT.string,
  margin: PT.oneOf(['dense', 'none', 'normal']),
  controlProps: PT.object,
  disabled: PT.bool,
  flexDirection: PT.oneOf(['row', 'column'])
};

Radio.propTypes = propTypes;
FormikRadio.propTypes = propTypes;

export { Radio, FormikRadio };
