import { styled } from '@mui/material/styles';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers-pro';

export const StyledDatePicker = styled(DatePicker)(({ value }) => ({
  '& .MuiInputLabel-root': {
    lineHeight: '1.2rem',
    transform: value
      ? 'translate(14px, -7px) scale(0.75)'
      : 'translate(10px, 7px) scale(1)'
  },
  '& .MuiInputLabel-root.Mui-focused': {
    transform: 'translate(14px, -7px) scale(0.75) !important'
  }
}));

export const StyledDateTimePicker = styled(DateTimePicker)(({ value }) => ({
  '& .MuiInputLabel-root': {
    lineHeight: '1.2rem',
    transform: value
      ? 'translate(14px, -7px) scale(0.75)'
      : 'translate(10px, 7px) scale(1)'
  },
  '& .MuiInputLabel-root.Mui-focused': {
    transform: 'translate(14px, -7px) scale(0.75) !important'
  }
}));
