export const fullNameColumnWidth = 175;
export const emailColumnWidth = 290;
export const phoneNumberColumnWidth = 110;
export const dateTimeColumnWidth = 170;
export const eventsColumnWidth = 100;
export const ipAddressColumnWidth = 120;

export const cellClassNameRed = 'cell--red';
export const totalValueCellClassName = 'cell--totalValue';
export const COLUMN_DROPDOWN_FILTER_NULL_OPTION_VALUE = 'null';
