import Router from 'next/router';
import dayjs from 'dayjs';

import Storage from './storage';

import {
  EXPIRE_KEY,
  ISSUED_KEY,
  PERMISSION_KEY,
  REDIRECT_KEY,
  REFRESH_KEY,
  REFRESH_PROCESSING_KEY,
  REFRESH_TIME,
  ROLE_KEY,
  SESSION_KEY
} from '@constants/common';
import Api from '@utils/api';
import routes from '@constants/routes';

class AuthSession {
  static sessionKey = SESSION_KEY;
  static permKey = PERMISSION_KEY;
  static refreshKey = REFRESH_KEY;
  static expireKey = EXPIRE_KEY;
  static refreshProcessingKey = REFRESH_PROCESSING_KEY;
  static issuedKey = ISSUED_KEY;
  static roleKey = ROLE_KEY;
  static refreshTime = null;

  static isTokenSet() {
    const authToken = Storage.get(AuthSession.sessionKey);
    return authToken && !!authToken.trim();
  }

  static setHeader() {
    if (AuthSession.isTokenSet()) {
      Api.defaults.headers.Authorization = `Bearer ${Storage.get(
        AuthSession.sessionKey
      )}`;
    }
    AuthSession.refreshTime = REFRESH_TIME;
  }

  static removeHeader() {
    delete Api.defaults.headers.Authorization;
  }

  static set({ token, permissions, ...additionalData }) {
    Storage.set(AuthSession.sessionKey, token);
    permissions && Storage.set(AuthSession.permKey, permissions);
    this.setAdditionalData(additionalData);
    AuthSession.setHeader();
  }

  static remove() {
    Storage.remove(AuthSession.sessionKey);
    Storage.remove(AuthSession.permKey);
    Storage.remove(AuthSession.refreshKey);
    Storage.remove(AuthSession.expireKey);
    AuthSession.removeHeader();
  }

  static login({ [REDIRECT_KEY]: redirectPath, ...props }) {
    AuthSession.set(props);
    if (redirectPath) {
      return Router.push(redirectPath);
    }
    Router.push(routes.debitCards.index);
  }

  static logout() {
    AuthSession.remove();
    Router.push({
      pathname: routes.auth.login,
      query: { [REDIRECT_KEY]: Router.pathname }
    });
  }

  static shouldRefresh(refreshDelayMS) {
    const tokenDueTo = Storage.get(AuthSession.expireKey);
    if (AuthSession.isTokenSet() && tokenDueTo) {
      const issued = Storage.get(AuthSession.issuedKey);
      const shouldRefresh = dayjs().isAfter(dayjs(issued).add(refreshDelayMS));
      if (Storage.get(AuthSession.refreshProcessingKey) && !shouldRefresh) {
        Storage.remove(AuthSession.refreshProcessingKey);
      }
      if (!Storage.get(AuthSession.refreshProcessingKey)) {
        return shouldRefresh;
      }
    }
    return false;
  }

  static getRefreshToken() {
    return Storage.get(AuthSession.refreshKey);
  }

  static startRefreshToken() {
    Storage.set(AuthSession.refreshProcessingKey, 'yes');
  }

  static endRefreshToken() {
    Storage.remove(AuthSession.refreshProcessingKey);
  }

  static setAdditionalData({ refreshToken, expiresIn, issued, role }) {
    Storage.set(AuthSession.refreshKey, refreshToken);
    Storage.set(AuthSession.expireKey, dayjs() + expiresIn * 1000);
    Storage.set(AuthSession.issuedKey, issued);
    Storage.set(AuthSession.roleKey, role);
  }

  static getPermissions() {
    return Storage.get(AuthSession.permKey);
  }

  static wasTokenGrantedYesterday() {
    const issuedDate = dayjs(Storage.get(AuthSession.issuedKey));
    const startOfCurrentDay = dayjs().startOf('day');
    return issuedDate.isBefore(startOfCurrentDay);
  }
}

export default AuthSession;
