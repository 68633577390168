import React from 'react';
import PT from 'prop-types';
import { Form, Formik } from 'formik';
import qs from 'query-string';

import Button from '@components/shared/Button/Button';
import FormikField from '@components/shared/Form/FormikField/FormikField';
import Box from '@components/shared/Box/Box';

// TODO: move styles to a separate file (srt 47, 65)

const getGridForField = () => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 65px',
  alignItems: 'end'
});

const ExpDateFilter = (props) => {
  const { item, applyValue } = props;

  const parsedLocation = qs.parse(location.search);
  const parsedValues =
    parsedLocation?.$filter && JSON.parse(parsedLocation?.$filter);

  // TODO: refactor this
  const firstField = 'expiryMonth';
  const secondField = 'expiryYear';

  const initialValues = {
    [firstField]: parsedValues?.[firstField]?.contains,
    [secondField]: parsedValues?.[secondField]?.contains
  };

  const handleSubmit = (values, { setSubmitting }) => {
    applyValue({
      ...item,
      value: {
        expiryMonth: values.expiryMonth,
        expiryYear: values.expiryYear
      }
    });

    setSubmitting(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <Form className="page-filter">
        <Box sx={getGridForField}>
          <FormikField
            type="text"
            name={firstField}
            label="Month"
            size="small"
            filterVariant
            sx={{ width: 90 }}
          />
          <FormikField
            type="text"
            name={secondField}
            label="Year"
            size="small"
            filterVariant
            sx={{ width: 90 }}
          />
          <Box style={{ marginBottom: 6, marginLeft: 'auto' }}>
            <Button type="submit" filterVariant>
              Apply
            </Button>
          </Box>
        </Box>
      </Form>
    </Formik>
  );
};

ExpDateFilter.propTypes = {
  item: PT.any,
  applyValue: PT.func
};

export default ExpDateFilter;
