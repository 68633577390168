import { MESSAGES_ERROR } from './errorMessages';

export default {
  /* INFO: Disabled rule because it's a YUP schema template  */
  /* eslint-disable no-template-curly-in-string */
  string: {
    length: '${path} must be exactly ${length} characters',
    min: '${path} must be at least ${min} characters',
    max: '${path} must be at most ${max} characters',
    matches: '${path} must match the following: "${regex}"',
    email: '${path} must be a valid email',
    url: '${path} must be a valid URL',
    trim: '${path} must be a trimmed string',
    lowercase: '${path} must be a lowercase string',
    uppercase: '${path} must be a upper case string',
    required: MESSAGES_ERROR.ERR001
  },
  number: {
    min: '${min} character minimum',
    max: '${max} character maximum',
    lessThan: '${path} must be less than ${less}',
    moreThan: '${path} must be greater than ${more}',
    notEqual: '${path} must be not equal to ${notEqual}',
    positive: '${path} must be a positive number',
    negative: '${path} must be a negative number',
    integer: '${path} must be an integer',
    required: MESSAGES_ERROR.ERR001
  },
  date: {
    min: '${path} field must be later than ${min}',
    max: '${path} field must be at earlier than ${max}',
    required: MESSAGES_ERROR.ERR001
  },
  array: {
    min: '${path} field must have at least ${min} items',
    max: '${path} field must have less than or equal to ${max} items'
  },
  object: {
    noUnknown:
      '${path} field cannot have keys not specified in the object shape'
  },
  mixed: {
    default: '${path} is invalid',
    required: MESSAGES_ERROR.ERR001,
    oneOf: '${path} must be one of the following values: ${values}',
    notOneOf: '${path} must not be one of the following values: ${values}'
  }
  /* eslint-enable no-template-curly-in-string */
};
