const defaultFormatChars = {
  9: /[0-9]/,
  a: /[A-Za-z]/,
  '*': /[A-Za-z0-9]/
};

/*
// mind the trap, it removes everything except
// 1) escaped symbols
// 2) defaultFormatChars which satisfies regular exp)

// input: maskValue = "+1 551 515 1515"; mask = "+\1 999 999 999*"
// output: plainValue = "15515151515"
*/

export const getPlainValue = (
  value,
  mask,
  formatChars = defaultFormatChars
) => {
  if (!value) return value;

  const formatCharsKeys = Object.keys(formatChars);

  let newValue = '';
  let maskCharIdx = 0;
  let valueCharIdx = 0;

  while (maskCharIdx < mask.length) {
    const maskChar = mask[maskCharIdx];
    const valueChar = value[valueCharIdx];

    if (maskChar === '\\') {
      newValue += valueChar;
      maskCharIdx++; // skip that position
    } else {
      if (formatCharsKeys.includes(maskChar)) {
        if (!formatChars[maskChar].test(valueChar)) {
          // exit when one of chars invalid
          maskCharIdx = mask.length;
        } else {
          newValue += valueChar;
        }
      }
    }

    maskCharIdx++;
    valueCharIdx++;
  }

  return newValue;
};
