import Router from 'next/router';
import buildQuery from 'odata-query';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import camelCase from 'lodash/camelCase';

import {
  ADMIN_ROLE,
  PERMISSION_KEY,
  ROLE_KEY,
  dateTimeFormat,
  noFieldData
} from '@constants/common';
import { selectCurrentUser } from '@store/currentUser';
import Storage from '@utils/storage';
import { cardTypes } from '@components/DebitCards/DebitCards.config';

const COMMON_ERROR_MESSAGE = 'Something went wrong. Please try again later.';

const Helpers = {
  isClient() {
    return typeof window !== 'undefined';
  },
  isCurrentUser(userId) {
    const currentUser = Helpers.isClient() && useSelector(selectCurrentUser);
    return currentUser?.userId === userId;
  },
  isAdmin() {
    return Storage.get(ROLE_KEY) === ADMIN_ROLE;
  },
  redirectTo(server, to) {
    if (server) {
      server.writeHead(302, {
        Location: to
      });
      server.end();
    } else {
      Router.push(to);
    }
  },
  handleServerErrors(err) {
    let errors = {};
    if (err.response && err.response.data) {
      const { data } = err.response;
      if (typeof data !== 'string') {
        Object.keys(data).forEach((key) => {
          errors[key] = Array.isArray(data[key])
            ? data[key].join(', ')
            : data[key];
        });
      } else {
        errors = data;
      }
    }
    return errors;
  },

  handleFormErrors(error, { formikActions }) {
    let nonFieldErrors = null;

    if (error?.detail || error?.title || error?.message) {
      nonFieldErrors = error.detail || error.title || error?.message;
    } else {
      nonFieldErrors = COMMON_ERROR_MESSAGE;
    }

    let formattedErrors = {};

    if (error?.errors) {
      const errors = error.errors;
      formattedErrors = Object.keys(errors).reduce((acc, item) => {
        const fieldName = camelCase(item);
        return { ...acc, [fieldName]: errors[item][0] };
      }, {});
    }

    formikActions.setErrors({ ...formattedErrors, nonFieldErrors });
  },
  getActionPermission(section) {
    const isAdmin = Helpers.isAdmin();
    const permissions = Storage.get(PERMISSION_KEY);
    if (permissions) {
      if (isAdmin) {
        return true;
      } else {
        return permissions.includes(section);
      }
    }
    return false;
  },
  checkPerm(permissions, item) {
    if (!item) {
      return true;
    }
    if (!Array.isArray(item.perm)) {
      return permissions.includes(item.perm);
    }
    switch (item.operator) {
      case 'AND': {
        return item.perm.every((permItem) => permissions.includes(permItem));
      }
      case 'OR': {
        return item.perm.some((permItem) => permissions.includes(permItem));
      }
    }
  },
  cutString(string = '', chars, overflow = '...') {
    const overflowStr = string.length > chars ? overflow : '';
    return string.substr(0, chars) + overflowStr;
  },
  tinyNumber(amount) {
    if (!amount) {
      return 0;
    }
    if (amount < 1000) {
      return amount;
    } else {
      return `${parseFloat(amount / 1000).toFixed(1)}K`;
    }
  },
  mbToBytes(mb) {
    return mb * 1048576;
  },
  getPagesNumber(count, pageSize = 20) {
    return Math.ceil(count / pageSize);
  },
  objToString(obj) {
    return Object.values(obj).join(', ');
  },
  hasRole(...roles) {
    const rolesString = Storage.get(ROLE_KEY);
    if (!rolesString) {
      return true;
    }
    const currentRoles = JSON.parse(rolesString);
    return roles.some((role) => currentRoles.includes(role));
  },
  parseODataQuery(params) {
    try {
      params.$filter = JSON.parse(params.$filter);
      // eslint-disable-next-line no-empty
    } catch {}
    const paramsWithRenamedKeys = Object.fromEntries(
      Object.entries(params).map(([key, value]) => [
        key.slice(1, key.length),
        value
      ])
    );

    const queryString = buildQuery(paramsWithRenamedKeys);
    return queryString.slice(1, queryString.length);
  },
  parseCSVODataRequest(params) {
    let parsedParams = Helpers.parseODataQuery(params);

    const dateFilters = parsedParams.matchAll(/(?:ge|le)\s('([^\s]+)')/g);

    for (const dateFilter of dateFilters) {
      parsedParams = parsedParams.replace(dateFilter[1], dateFilter[2]);
    }

    return parsedParams;
  },
  getDisplayName(data) {
    return [data?.firstName, data?.lastName].filter(Boolean).join(' ');
  },
  formatDate(date, format) {
    if (!date) return noFieldData;
    return dayjs(date).format(format || dateTimeFormat);
  },
  separateDateFromMask(date) {
    return {
      expiryMonth: date.slice(0, 2),
      expiryYear: date.slice(-2)
    };
  },
  getCardMask(bin, l4) {
    if (!bin || !l4) return noFieldData;
    return `${bin} ****** ${l4}`;
  },
  getCardAsNumber(cardNumber) {
    if (!cardNumber) return null;
    return cardNumber.replace(/[-\s]/g, '');
  },
  formatPhoneNumber(phoneNumber) {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
  },
  formatExpDate(data, numbersOnly = false) {
    if (!data?.expiryMonth && !data?.expiryYear) {
      return '';
    }

    let month = data?.expiryMonth.toString();
    let year = data?.expiryYear.toString();

    if (month?.length === 1) {
      month = `0${month}`;
    }
    if (year?.length === 1) {
      year = `0${year}`;
    }

    if (numbersOnly) {
      return `${month}${year}`;
    }
    return `${month}/${year}`;
  },
  getCardType(_number) {
    if (!_number) return '';
    const number = _number?.replace(/[-\s]/g, '');

    const rules = {
      electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
      maestro:
        /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
      dankort: /^(5019)\d+$/,
      interPayment: /^(636)\d+$/,
      unionPay: /^(62|88)\d+$/,
      visa: /^4[0-9]{5,12}(?:[0-9]{3})?$/,
      mastercard:
        /^5[1-5][0-9]{0,14}|^(222[1-9]|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{0,12}$/,
      amex: /^3[47][0-9]{0,13}$/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{0,11}$/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{0,12}$/,
      jcb: /^(3(?:088|096|112|158|337|5(?:2[89]|[3-8][0-9]))\d{12})$/,
      jcb15: /^(?:2131|2100|1800|35[0-9]{3})[0-9]{11}$/
    };

    const typeKey = Object.keys(rules).find((key) => {
      if (rules[key].test(number)) {
        return key;
      }
    });
    return cardTypes[typeKey] || 'Unknown';
  },
  // when default required prop doesn't apply
  getFieldLabelWithAsterisk(label, isRequired) {
    if (!isRequired) {
      return label;
    }
    return `${label}*`;
  }
};

export default Helpers;
