import React from 'react';

import { StyledTextField } from './styled';

const TextField = (props) => (
  // eslint-disable-next-line react/prop-types
  <StyledTextField disabled={props.disabled || props.readOnly} {...props} />
);

export default TextField;
