export const getButtonStyle = (color, additionalStyles) => ({
  width: '44px',
  height: '44px',
  transition: 'opacity 0.15s ease-in-out',
  marginLeft: '-12px',
  '&:hover': {
    opacity: 0.8
  },
  '.MuiSvgIcon-root': {
    color
  },
  '&.Mui-disabled': {
    color,
    opacity: 0.5
  },
  ...additionalStyles
});
