import React, { useState } from 'react';
import PT from 'prop-types';
import { Field } from 'formik';
// TODO: add components as shared
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';

import { fieldTypes } from './FormikField/FormikField';
import { StyledFormControl } from './styled';

import TextField from '@components/shared/TextField/TextField';
import IconButton from '@components/shared/IconButton/IconButton';
import FormHelperText from '@components/shared/Form/FormHelperText';

const FormInput = ({
  autoComplete: propAutoComplete,
  fullWidth,
  InputProps,
  margin,
  error,
  size,
  value,
  filterVariant,
  ...rest
}) => {
  const autoComplete =
    propAutoComplete !== undefined && !propAutoComplete
      ? 'new-password'
      : propAutoComplete;

  return (
    <StyledFormControl
      size={size}
      margin={margin}
      fullWidth={fullWidth}
      filterVariant={filterVariant}
    >
      <TextField
        size={size}
        {...rest}
        value={value ?? ''}
        InputLabelProps={{ shrink: !!value || undefined }}
        InputProps={{
          ...InputProps,
          autoComplete
        }}
        error={!!error}
        style={{ margin: 0 }}
        variant={filterVariant ? 'standard' : 'outlined'}
      />
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </StyledFormControl>
  );
};

const EndAdornmentPassword = ({ show, onClick, disabled }) => (
  <InputAdornment position="end">
    <IconButton
      aria-label="toggle password visibility"
      disabled={disabled}
      onClick={onClick}
    >
      {show ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  </InputAdornment>
);

EndAdornmentPassword.propTypes = {
  show: PT.bool.isRequired,
  onClick: PT.func.isRequired,
  disabled: PT.bool
};

const FormInputPassword = ({ InputProps, type, disabled, ...rest }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleBlur = (event) => {
    rest.onBlur?.(event);

    if (typeof rest.value === 'string') {
      rest.onChange({
        target: {
          name: rest.name,
          value: rest.value.trim()
        }
      });
    }
  };

  return (
    <FormInput
      {...rest}
      disabled={disabled}
      type={showPassword ? 'text' : type}
      onBlur={handleBlur}
      InputProps={{
        ...InputProps,
        endAdornment: InputProps?.endAdornment ?? (
          <EndAdornmentPassword
            disabled={disabled}
            show={showPassword}
            onClick={handleClickShowPassword}
            variant="outlined"
          />
        )
      }}
    />
  );
};

const FormikFormInput = ({ name, type, onBlurHandler, ...rest }) => (
  <Field name={name}>
    {({ field, meta }) => {
      const error = meta.touched ? meta.error : '';

      if (type === fieldTypes.password) {
        return (
          <FormInputPassword error={error} type={type} {...field} {...rest} />
        );
      }

      const handleBlur = (event) => {
        field.onBlur?.(event);
        if (onBlurHandler) {
          onBlurHandler(event);
        }

        if (typeof field.value === 'string') {
          field.onChange({
            target: {
              name: field.name,
              value: field.value.trim()
            }
          });
        }
      };

      return (
        <FormInput
          error={error}
          type={type}
          {...field}
          onBlur={handleBlur}
          {...rest}
        />
      );
    }}
  </Field>
);
FormInput.defaultProps = {
  margin: 'normal',
  size: 'small'
};

const propTypes = {
  label: PT.string,
  name: PT.string.isRequired,
  error: PT.oneOfType([PT.string, PT.bool]),
  helperText: PT.string,
  endIcon: PT.node,
  fullWidth: PT.bool,
  InputProps: PT.object,
  autoComplete: PT.oneOf([false]),
  type: PT.string,
  endAdornment: PT.oneOfType([PT.string, PT.element]),
  margin: PT.oneOf(['dense', 'none', 'normal']),
  size: PT.oneOf(['medium', 'small']),
  onBlurHandler: PT.func,
  value: PT.any,
  disabled: PT.bool,
  filterVariant: PT.bool
};

FormInput.propTypes = propTypes;
FormInputPassword.propTypes = propTypes;
FormikFormInput.propTypes = propTypes;

export { FormInput, FormikFormInput };
