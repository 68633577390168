import React from 'react';
import PT from 'prop-types';

import { commonButtonStyles } from './config';

import { EditIcon } from '@components/shared/icons/Icons';
import IconButton from '@components/shared/IconButton/IconButton';

const EditActionButton = ({ hidden, ...props }) =>
  !hidden && (
    <IconButton
      htmlType="button"
      size="small"
      sx={commonButtonStyles}
      {...props}
    >
      <EditIcon />
    </IconButton>
  );

EditActionButton.defaultProps = {
  hidden: false
};

EditActionButton.propTypes = {
  hidden: PT.bool
};

export default EditActionButton;
