import React, { useState } from 'react';
import PT from 'prop-types';

import { StyledDeleteModal } from '../styled';

import { commonButtonStyles } from './config';

import Button from '@components/shared/Button/Button';
import IconButton from '@components/shared/IconButton/IconButton';
import Stack from '@components/shared/Stack/Stack';
import Box from '@components/shared/Box/Box';
import { DeleteIcon } from '@components/shared/icons/Icons';

const ConfirmActionButton = ({ icon, hidden, modalProps, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      {!hidden && (
        <IconButton
          size="small"
          sx={commonButtonStyles}
          onClick={handleOpen}
          aria-controls={modalProps.itemId}
          {...props}
        >
          {icon}
        </IconButton>
      )}
      <StyledDeleteModal
        id={modalProps.itemId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': modalProps.itemId
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        component="div"
      >
        <Box pb={1}>{modalProps.content}</Box>
        <Stack direction="row" justifyContent="flex-end" gap={1}>
          <Button variant="outlined" size="mini" onClick={handleClose}>
            {modalProps.disagreeText || 'No'}
          </Button>
          <Button
            size="mini"
            onClick={() => {
              modalProps.onConfirm();
              handleClose();
            }}
          >
            {modalProps.agreeText || 'Yes'}
          </Button>
        </Stack>
      </StyledDeleteModal>
    </>
  );
};

ConfirmActionButton.defaultProps = {
  icon: <DeleteIcon />,
  hidden: false
};

ConfirmActionButton.propTypes = {
  icon: PT.node.isRequired,
  hidden: PT.bool,
  modalProps: PT.shape({
    itemId: PT.oneOfType([PT.string, PT.number]).isRequired,
    title: PT.string.isRequired,
    onConfirm: PT.func.isRequired,
    content: PT.string,
    agreeText: PT.string,
    disagreeText: PT.string
  })
};

export default ConfirmActionButton;
