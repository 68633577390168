import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import InputMask from 'react-input-mask';

import { getPlainValue } from './InputMasked.utils';

import TextField from '@components/shared/TextField/TextField';

const InputMasked = ({
  entireFillingRequired,
  mask,
  value,
  name,
  label,
  placeholder,
  onChange,
  onBlur,
  setError,
  invalid,
  alwaysShowMask,
  maskChar,
  plainValue,
  canBeNotCompleted,
  ...props
}) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  let maskInputValue = value;
  let handleChange = onChange;
  let handleBlur = onBlur;

  if (plainValue) {
    maskInputValue = localValue;

    handleChange = (e) => {
      const newValue = e.target.value;

      setLocalValue(newValue);
      onChange({
        target: {
          name,
          value: getPlainValue(newValue, mask, props.formatChars)
        }
      });
    };

    handleBlur = (e) => {
      const newValue = e.target.value;
      const newPlainValue = getPlainValue(newValue, mask, props.formatChars);

      onBlur({
        target: {
          name,
          value: newPlainValue
        }
      });
    };
  }

  return (
    <InputMask
      mask={mask}
      value={maskInputValue}
      onChange={handleChange}
      onBlur={(e) => {
        handleBlur(e);
        if (
          !canBeNotCompleted &&
          e.target.value.includes(maskChar) &&
          setError &&
          entireFillingRequired
        ) {
          setError('Field not completed');
        }
      }}
      alwaysShowMask={alwaysShowMask}
      maskChar={maskChar}
    >
      {() => (
        <TextField
          type="text"
          name={name}
          label={label}
          placeholder={placeholder}
          error={invalid}
          {...props}
        />
      )}
    </InputMask>
  );
};

InputMasked.defaultProps = {
  alwaysShowMask: false,
  maskChar: '_',
  plainValue: true,
  entireFillingRequired: true,
  canBeNotCompleted: false
};

InputMasked.propTypes = {
  mask: PT.string,
  value: PT.string,
  onChange: PT.func,
  onBlur: PT.func,
  setError: PT.func,
  name: PT.string,
  placeholder: PT.string,
  invalid: PT.bool,
  alwaysShowMask: PT.bool,
  label: PT.string,
  formatChars: PT.object,
  plainValue: PT.bool,
  entireFillingRequired: PT.bool,
  canBeNotCompleted: PT.bool,
  maskChar: PT.any
};

export default InputMasked;
