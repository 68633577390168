import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const { nodeEnv } = publicRuntimeConfig;

const isDevelopmentEnvironment = nodeEnv === 'development';

/* eslint-disable no-console */
const LoggerRaw = {
  log(...args) {
    console.log(...args);
  },
  info(...args) {
    console.info(...args);
  },
  warn(...args) {
    console.warn(...args);
  },
  error(...args) {
    console.error(...args);
  }
};
/* eslint-enable no-console */

const Logger = new Proxy(LoggerRaw, {
  get: function (target, name) {
    if (!isDevelopmentEnvironment) {
      return () => void 0;
    }
    return target[name];
  }
});

export default Logger;
