import qs from 'query-string';

import AuthSession from '@utils/auth-session';
import { grantTypes } from '@constants/common';
import { apiUrls } from '@constants/api';
import Api from '@utils/api';
import helpers from '@utils/helpers';

export const refreshTokenHandler = async () => {
  try {
    return await Api.post(
      apiUrls.auth.login,
      qs.stringify({
        refresh_token: AuthSession.getRefreshToken(),
        grant_type: grantTypes.refreshToken
      }),
      {
        headers: {
          'content-Type': 'application/x-www-form-urlencoded'
        },
        refreshToken: true
      }
    );
  } catch (e) {
    if (e.response?.status === 400) {
      AuthSession.logout();
    }

    return Promise.reject(helpers.handleServerErrors(e));
  }
};
