import React, { createContext } from 'react';
import PT from 'prop-types';

export const SchemaContext = createContext({ schema: undefined });

export const SchemaProvider = ({ schema, children }) => (
  <SchemaContext.Provider value={{ schema }}>{children}</SchemaContext.Provider>
);

SchemaProvider.propTypes = {
  schema: PT.object.isRequired,
  children: PT.any.isRequired
};

export default SchemaProvider;
