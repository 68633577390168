import { styled } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';

import { palette } from '@utils/theme';

export const StyledTextField = styled(MuiTextField)(({ value, readOnly }) => ({
  '& .MuiInputLabel-root': {
    lineHeight: '1.2rem',
    transform:
      value || value === 0
        ? 'translate(14px, -7px) scale(0.75)'
        : 'translate(10px, 7px) scale(1)'
  },
  '& .MuiInputLabel-root.Mui-focused': {
    transform: 'translate(14px, -7px) scale(0.75) !important'
  },
  '& .MuiInputBase-multiline': {
    padding: '0 !important'
  },
  '& .MuiInputBase-input': {
    '-webkit-text-fill-color': readOnly && `${palette.dark} !important`
  }
}));
