import React from 'react';
import { getGridStringOperators } from '@mui/x-data-grid-premium';

import Box from '@components/shared/Box/Box';
import ShowDetailsActionButton from '@components/shared/DataTable/actions/ShowDetailsActionButton';
import { cleanObject } from '@utils/object-cleaner';
import { actionTypes, noFieldData } from '@constants/common';
import EditActionButton from '@components/shared/DataTable/actions/EditActionButton';
import {
  fullNameColumnWidth,
  dateTimeColumnWidth
} from '@components/shared/DataTable/DataTable.constants';
import ExpDateFilter from '@components/shared/DataTable/filters/ExpDateFilter';
import { muiDataTableTypes } from '@components/shared/ODataPaginatedTable/ODataPaginatedTable.config';
import Helpers from '@utils/helpers';
import { permissionsMap } from '@constants/permissions';
import { filterOperators } from '@constants/filters';
import DeleteActionButton from '@components/shared/DataTable/actions/DeleteActionButton';

export const cardTypes = {
  electron: 'Electron',
  maestro: 'Maestro',
  dankort: 'Dankort',
  interPayment: 'InterPayment',
  unionPay: 'UnionPay',
  visa: 'Visa',
  mastercard: 'Mastercard',
  amex: 'Amex',
  diners: 'Diners Club',
  discover: 'Discover',
  jcb: 'JCB',
  jcb15: 'JCB 15'
};

export const getColumns = ({
  setCardId,
  setAction,
  cardTypesOptions,
  handleDeleteDCCard
}) => [
  {
    headerName: 'Actions',
    field: 'actions',
    type: 'actions',
    width: Helpers.isAdmin() ? 80 : 60,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <Box size={4}>
        <EditActionButton
          onClick={() => {
            setCardId(row.cardNumberTokenId);
            setAction(actionTypes.edit);
          }}
          hidden={!Helpers.getActionPermission(permissionsMap.debitCardManage)}
        />
        <ShowDetailsActionButton
          onClick={() => {
            setCardId(row.cardNumberTokenId);
            setAction(actionTypes.details);
          }}
        />
        <DeleteActionButton
          content="Are you sure to delete this card?"
          onConfirm={() => handleDeleteDCCard(row.cardNumberTokenId)}
          hidden={!Helpers.isAdmin()}
        />
      </Box>
    )
  },
  {
    headerName: 'ID',
    field: 'cardId',
    width: 55,
    type: muiDataTableTypes.number,
    renderCell: ({ row, field }) => row[field]
  },
  {
    headerName: 'Card Number',
    field: 'ccNumberBin',
    width: 130,
    renderCell: ({ row }) =>
      Helpers.getCardMask(row.ccNumberBin, row.ccNumberLast4Digits),
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === 'contains'
    )
  },
  {
    headerName: 'Exp. Date',
    field: 'expiryMonth',
    width: 70,
    renderCell: ({ row }) => Helpers.formatExpDate(row),
    type: muiDataTableTypes.expDate,
    filterOperators: [
      filterOperators.equals((props) => (
        <ExpDateFilter {...props} fieldName="ccExpDate" />
      ))
    ]
  },
  {
    headerName: 'Cardholder Name',
    field: 'cardHolderName',
    width: fullNameColumnWidth
  },
  {
    headerName: 'Card Type',
    field: 'cardType',
    width: 125,
    type: muiDataTableTypes.singleSelect,
    valueOptions: cardTypesOptions,
    renderCell: ({ row, field }) => row[field] || noFieldData
  },
  {
    headerName: 'Issuing Bank',
    field: 'issuingBank',
    width: 120,
    renderCell: ({ row, field }) => row[field] || noFieldData
  },
  {
    headerName: 'Created Date',
    field: 'createdDate',
    width: dateTimeColumnWidth,
    type: muiDataTableTypes.dateRange,
    valueFormatter: (createdDate) => Helpers.formatDate(createdDate),
    renderCell: ({ row }) => Helpers.formatDate(row.createdDate),
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Updated Date',
    field: 'updatedDate',
    width: dateTimeColumnWidth,
    type: muiDataTableTypes.dateRange,
    valueFormatter: (updatedDate) => Helpers.formatDate(updatedDate),
    renderCell: ({ row }) => Helpers.formatDate(row.updatedDate),
    filterOperators: [filterOperators.dateRange]
  },
  {
    headerName: 'Card Token',
    field: 'cardNumberTokenId',
    width: 285,
    filterable: false
  }
];

export const initialFilterParams = cleanObject({
  cardId: {
    eq: ''
  },
  cardHolderName: {
    contains: ''
  },
  cardType: {
    eq: ''
  },
  issuingBank: {
    contains: ''
  },
  cardNumberTokenId: {
    contains: ''
  }
});

export const actionMessages = {
  details: 'Debit card details',
  edit: 'Edit debit card'
};
